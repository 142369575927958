import './Leadership.css'

const Leadership = props => (
    <div id="leadership">
        <h2>Ozarks NATS Leadership</h2>
        <p><strong>{props.president}</strong>, President<br/><strong>{props.vp}</strong>, Vice-President & Auditions Chair<br /><strong>{props.secretary}</strong>, Secretary-Treasurer</p>
        <p></p>
    </div>
);

export default Leadership;
