// import headerLogo from './NATS_75Years_HORIZ.jpg'
import headerLogo from './OzarkChapterHorizontal.svg'
import natsLogo from './Nats+Logo.png'
import findATeacher from './Find+a+Teacher.png'
import natsFoundation from './NATS+Foundation+Logo.jpg'
import natsFoundationFacebook from './NATS+Foundation+Facebook+Image.jpg'
import vocapedia from './Vocapedia+Logo.png'
import natsCompetitions from './NATS+Competitions.jpg'
import './ContentWrapper.css'
import Leadership from "./Leadership"


const ContentWrapper = () => (
    <div id="wrapper">
        <div id="header">
            <img src={headerLogo} alt={"NATS Header Logo"} width="75%" />
        </div>
        <div id="column-left"><Leadership president="Jena Cantrel" vp="Sarah Howes" secretary="Traci Pritchard" /></div>
        <div id="column-center">
            <h1 className='centerStyle'>Welcome Ozark Chapter NATS members!</h1>
            <h2 className='centerStyle'>Student Auditions</h2>
            <h3 className='centerStyle'>Saturday, October 7, 2023</h3>
            <section className='boldCenterStyle'>
                <p>Thank you to our hosts at Evangel University<br />
                1111 N Glenstone Ave, Springfield, MO 65802<br />
            </p>
            </section>
            <hr />
            <section>
                <p><i>Founded in 1944, National Association of Teachers of Singing, Inc. (NATS) is the largest professional association of teachers of singing in the world with nearly 7,000 members in the United States, Canada, and nearly 30 other countries.</i></p>
                <p><i>Ozark NATS includes members who live or work in Southwest Missouri. Check out our <a href={"https://www.facebook.com/groups/325145302657902"} target={"_blank"} rel={"noopener noreferrer"}>Facebook Group!</a></i></p>
            </section>

            <h2>Mission Statement</h2>
            <section>
                <p>To encourage the highest standards of the vocal art and of ethical principles in the teaching of singing; and to promote vocal education and research at all levels, both for the enrichment of the general public and for the professional advancement of the talented.</p>
            </section>
        </div>
        <div id="column-right">
            <a href={"https://www.nats.org/"} target={"_blank"} rel={"noopener noreferrer"}><img src={natsLogo} alt={""} width="200" /></a>
            <a href={"https://www.nats.org/cgi/page.cgi/find_teacher.html"} target="_blank" rel="noopener noreferrer"><img src={findATeacher} alt={""}  width="200" /></a>
            <a href={"https://www.nats.org/about_nats_foundation.html"} target="_blank" rel="noopener noreferrer"><img src={natsFoundation} alt={""}  width="200" /></a>
            <a href={"https://www.facebook.com/NATS-Foundation-2119730944968258/"} target="_blank" rel="noopener noreferrer"><img src={natsFoundationFacebook} alt={""} width="200px" /></a>
            <a href={"http://www.vocapedia.info/"} target={"_blank"} rel={"noopener noreferrer"}><img src={vocapedia} alt={""} width="200px" /></a>
        </div>
        <div id="footer">
            <a href={"https://www.nats.org/competitions.html"} target={"_blank"} rel={"noopener noreferrer"}><img src={natsCompetitions} alt={""} width="50%" /></a>
        </div>
    </div>
);

export default ContentWrapper;
